<template>
    <v-navigation-drawer class="drawer" v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" width="270" app dark
        color="galaxy">
        <router-link to="/" class="d-block my-10">
            <v-img :src="$appConfig.images.drawerLogo" width="200" height="53" contain class="mx-auto" />
        </router-link>

        <v-list dense expand>
            <v-list-item to="/proposals/new" class="primary" v-if="currentUser.role != 'assembler'">
                <v-list-item-icon>
                    <v-icon>mdi-storefront</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>
                        {{ $t('proposalGenerator') }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <template v-for="(item, i) in menuItems">
                <v-list-group v-if="item.children" :value="true" :key="i" :prepend-icon="item.icon"
                    active-class="white--text">
                    <template #activator>
                        <v-list-item-title>{{ item.label }}</v-list-item-title>
                    </template>

                    <v-list-item v-for="(child, ci) in item.children" :key="ci" :to="child.to" class="white--text"
                        exact>
                        <v-list-item-icon>
                            <span class="mx-auto">
                                -
                            </span>
                        </v-list-item-icon>

                        <v-list-item-title>
                            {{ child.label }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list-group>

                <v-list-item v-else :key="`item-${i}`" :to="item.to">
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            {{ item.label }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-list>
        
    </v-navigation-drawer>
</template>

<script>
export default {
    name: 'AppDrawer',
    props: {
        value: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        drawer: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        currentUser() {
            return this.$store.state.currentUser
        },
        isAdmin() {
            return this.currentUser?.admin;
        },
        menuItems: {
            get() {
                return this.$store.state.drawerMenuItems;
            },
            set(value) {
                this.$store.commit('setDrawerMenuItems', value);
            }
        }
    },
    methods: {
        addAdminMenuItems() {
            this.menuItems.push(
                {
                    label: this.$t('products'),
                    icon: "mdi-package-variant-closed",
                    children: [
                        {
                            label: this.$t('entityList', [this.$t('products')]),
                            to: '/products'
                        },
                        {
                            label: this.$t('groups'),
                            to: '/product-groups'
                        },
                        {
                            label: this.$tc('category', 2),
                            to: '/categories'
                        },
                        {
                            label: this.$tc('subcategory', 2),
                            to: '/subcategories'
                        },
                    ]
                },
                {
                    label: this.$tc('supplier', 2),
                    icon: "mdi-store",
                    to: '/suppliers'
                },
                {
                    label: this.$tc('user', 2),
                    icon: "mdi-account-multiple",
                    to: '/users'
                },
                {
                    label: this.$tc('order', 2),
                    icon: "mdi-factory",
                    children: [
                        {
                            label: this.$t('entityList', [this.$tc('order', 2)]),
                            to: "/orders",
                        },
                        {
                            label: this.$t('predefinedFields'),
                            to: "/order-settings",
                        }
                    ]
                },
                {
                    label: this.$tc('setting', 2),
                    icon: "mdi-cog",
                    to: "/settings",
                },
            )
        },
        addCommonMenuItems(type = 'seller') {
            if (['admin','seller'].includes(type)) {
                this.menuItems.push(
                    {
                        label: "Dashboard",
                        icon: "mdi-view-dashboard",
                        to: '/'
                    },
                    {
                        label: this.$t('proposals'),
                        icon: "mdi-file-document",
                        to: '/proposals'
                    },
                    {
                        label: this.$tc('myProfile'),
                        icon: "mdi-account-circle-outline",
                        to: '/my-profile'
                    },

                    {
                        label: this.$tc('client', 2),
                        icon: "mdi-account-group",
                        children: [
                            {
                                label: this.$t('entityList', [this.$tc('client', 2)]),
                                to: '/customers'
                            },
                            {
                                label: this.$t('proposalsPerClient'),
                                to: '/proposals-per-customer'
                            }
                        ]
                    },
                )
            }

            if (type === 'assembler') {
                this.menuItems.push(
                    {
                        label: this.$tc('myProfile'),
                        icon: "mdi-account-circle-outline",
                        to: '/my-profile'
                    },

                    {
                        label: this.$tc('client', 2),
                        icon: "mdi-account-group",
                        children: [
                            {
                                label: this.$t('entityList', [this.$tc('client', 2)]),
                                to: '/customers'
                            }
                        ]
                    },
                )
            }

        },
        setMenuItems() {

            this.menuItems = []

            if (!this.currentUser) {
                return;
            }

            this.addCommonMenuItems(this.currentUser.role);

            if (this.isAdmin) {
                this.addAdminMenuItems();
            }
        }
    },
    watch: {
        currentUser: {
            immediate: true,
            handler: 'setMenuItems'
        }
    },

}
</script>